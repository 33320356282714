import React from "react";
import HeroImage2 from "../assets/heroImage2.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";
import "../style/Style.css";


const Home = () => {
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800 ">

      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            <span className="text-[#C685C4]">Hello World!</span> <br/> <span className="text-[#EA5E5E]">I'm</span> <span className="text-[#4D77FF]">Alex,</span> <span className=" text-[#FBBF47]"> a Full Stack Developer <span className="text-[#52B4AE]"> based in </span> <span className=" text-[#C9F658]"> Los Angeles, CA. </span></span>
          </h2>
          <p className="text-gray-500 py-4 max-w-md">
             {/* Never compare yourself with others...  */}
          </p>

          <div>
            <Link to="portfolio" smooth duration={800} className="font-signature group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md cursor-pointer bg-[#4D77FF] button">
              Portfolio
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>

        <div>
          <img
            src={HeroImage2}
            alt="Alex"
            className="rounded-2xl mx-auto w-2/3 md:w-full"/>
        </div>
      </div>
    </div>
  );
};

export default Home;
