import React from "react";

const Contact = () => {
  return (
    <div name='contact' 
        className="w-full h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white">
      <div className=" flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <div className=" pb-8">
          <p className=" text-4xl font-bold inline border-b-4 border-gray-500">Contact</p>
          <p className=" py-6 font-signature">Get in touch with me!</p>
        </div>
        <div className=" flex justify-center items-center">
          <form 
          action="https://getform.io/f/b88ab60c-5991-4320-8d45-281fe4dc3a6a"
          method="POST" 
          className="flex flex-col w-full md:w-1/2" >
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className=" p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <textarea 
            name="message" 
            rows="10" 
            className=" p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            placeholder="Enter your message"></textarea>
            <button className=" font-signature text-white px-6 py-3 my-5 mx-auto flex items-center rounded-md button">Let's talk</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
